import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getComments(params) {
  return request({
    url: ENDPOINT.LIST_COMMENTS,
    method: 'get',
    params
  })
}

export function updateComment(data) {
  return request({
    url: ENDPOINT.COMMENT,
    method: 'patch',
    data
  })
}

export function deleteComment(params) {
  return request({
    url: ENDPOINT.COMMENT,
    method: 'delete',
    params
  })
}
