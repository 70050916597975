import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getTags(params) {
  return request({
    url: ENDPOINT.TAG,
    method: 'get',
    params
  })
}

export function createTag(data) {
  return request({
    url: ENDPOINT.TAG,
    method: 'post',
    data
  })
}

export function deleteTag(id) {
  return request({
    url: `${ENDPOINT.TAG}?id=${id}`,
    method: 'delete'
  })
}

export function updateTag(data) {
  return request({
    url: ENDPOINT.TAG,
    method: 'patch',
    data
  })
}
