<template>
  <el-dialog
    top="150px"
    width="500px"
    class="tag-form-popup"
    :title="`${!tag ? 'Thêm mới' : 'Cập nhật thông tin'} nhãn dán`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Tên nhãn dán" prop="name">
        <el-input
          v-model="form.name"
          placeholder="Nhập tên"
          :disabled="callingAPI"
        ></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input
          v-model="form.description"
          type="textarea"
          row="5"
          placeholder="Nhập mô tả"
          :disabled="callingAPI"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button
        v-if="!tag"
        type="primary"
        :loading="callingAPI"
        @click="handleCreateTag"
      >
        Tạo mới
      </el-button>
      <el-button
        v-else
        type="primary"
        :loading="callingAPI"
        @click="handleUpdateTag"
      >
        Cập nhật
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createTag, updateTag } from '@/services/tag'
export default {
  name: 'TagForm',
  props: {
    visible: Boolean,
    tag: Object,
    language: String
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: this.tag ? this.tag.name : '',
        description: this.tag ? this.tag.description : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    dataInput() {
      const payload = {
        locale: this.language,
        name: this.form.name,
        description: this.form.description
      }
      if (this.tag) {
        payload['id'] = this.tag.id
      } else {
        payload['code'] = this.form.name
      }
      return payload
    }
  },
  methods: {
    handleCreateTag() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          createTag(this.dataInput).then((response) => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Tạo mới thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('close')
            this.$emit('reload')
            this.$emit('add', response)
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Tạo mới thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleUpdateTag() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateTag(this.dataInput).then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: 'Cập nhật thành công'
            })
            this.$refs.form.resetFields()
            this.$emit('close')
            this.$emit('reload')
            this.callingAPI = false
          }).catch(() => {
            this.$notify.error({
              title: 'Thất bại',
              message: 'Cập nhật thất bại'
            })
            this.callingAPI = false
          })
        }
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
</style>
